import { Typography } from "@mui/material";
import { credits, musicDescription, palette, statement } from "../db";
import { CenterFlex, ColumnFlex } from "../style";

// const WelcomeButtonStyle = {
//   backgroundImage: 'url("/images/welcome-icon.png")',
//   backgroundPosition: 'center',
//   backgroundRepeat: 'no-repeat',
//   backgroundSize: 'contain',
//   width: '8rem',
//   height: '8rem',
//   marginTop: '-4rem',
//   marginBottom: '3rem',
// };

const AboutBannerStyle = {
  display: "block",
  backgroundImage: 'url("https://i.imgur.com/EvjkhNx.png")',
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  width: "100%",
};

const AboutStatementBlockStyle = {
  border: `${palette.primary} 2px solid`,
  width: "40rem",
  maxWidth: "100%",
  textAlign: "center",
  gap: ".5rem",
  padding: "1rem",
  paddingTop: "3rem",
  paddingBottom: "3rem",
  marginBottom: "3rem",
};

const MusicDesriptionBlockStyle = {
  border: `${palette.primary} 2px solid`,
  width: "40rem",
  maxWidth: "100%",
  textAlign: "left",
  gap: ".5rem",
  padding: "1rem",
  paddingTop: "3rem",
  paddingBottom: "3rem",
  marginBottom: "3rem",
};

const CreditsBlockStyle = {
  width: "40rem",
  maxWidth: "100%",
  textAlign: "center",
  gap: ".5rem",
  marginBottom: "3rem",
};

const About = () => {
  return (
    <div className="paper-container">
      <div
        style={{
          width: "50rem",
          maxWidth: "100%",
          ...CenterFlex,
          ...ColumnFlex,
        }}
      >
        <div className="about-banner" style={AboutBannerStyle} />
        {/* <div
      style={WelcomeButtonStyle}
    /> */}
        <div style={{ ...CreditsBlockStyle, ...CenterFlex, ...ColumnFlex }}>
          {credits.split("\n").map((p, index) => (
            <Typography variant="致謝內文" key={`p-${index}`}>
              {p}
            </Typography>
          ))}
        </div>
        <div
          style={{ ...AboutStatementBlockStyle, ...CenterFlex, ...ColumnFlex }}
        >
          {statement.split("\n").map((p, index) => (
            <Typography variant="關於內文" key={`p-${index}`}>
              {p}
            </Typography>
          ))}
        </div>
        {/* <InDevelopmentMask></InDevelopmentMask> */}
        <div
          style={{ ...MusicDesriptionBlockStyle, ...CenterFlex, ...ColumnFlex }}
        >
          {musicDescription.split("\n").map((p, index) => (
            <Typography variant="關於內文" key={`p-${index}`}>
              {p}
            </Typography>
          ))}
        </div>
        <audio
          style={{
            width: "100%",
            marginBottom: "3rem",
          }}
          controls
          src="/audio/music.m4a"
        ></audio>
      </div>
    </div>
  );
};

export default About;
