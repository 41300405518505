import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import About from "./routes/About";
import Feedback from "./routes/Feedback";
import Gallery from "./routes/Gallery";
import LandingPage from "./routes/LandingPage";
import Work from "./routes/Work";

const PageBackgroundStyle = {
  backgroundImage: 'url("/images/horizontal-bg.jpeg")',
  backgroundSize: "contain",
  backgroundRepeat: "repeat-y",
};

const AppWrapper = () => {
  return (
    <div className="page" style={PageBackgroundStyle}>
      <Header className="__debug" />
      <div className="main">
        <div className="main-content">
          <Outlet />
        </div>
        <Footer className="__debug" />
      </div>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    Component: LandingPage,
  },
  {
    path: "/*",
    Component: AppWrapper,
    children: [
      {
        path: "works/",
        Component: Gallery,
      },
      {
        path: "works/:name",
        Component: Work,
      },
      {
        path: "feedback/",
        Component: Feedback,
      },
      {
        path: "about/",
        Component: About,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
