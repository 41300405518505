import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { palette } from "./db";
import "./index.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#69b187",
      dark: "rgba(105, 177, 135, 0.05)",
      light: "rgba(105, 177, 135, 0.05)",
    },
    secondary: {
      main: "#AD8FC1",
    },
    bg: {
      main: "#F8F8F5",
    },
  },
  typography: {
    workTitle: {
      fontSize: "2rem",
      color: palette.primary,
    },
    作品理念標題: {
      fontSize: "1.5rem",
      color: palette.primary,
      fontWeight: "800",
      fontFamily: "Zen Maru Gothic, sans-serif",
    },
    作品理念內文: {
      fontSize: "1rem",
      color: "black",
      fontWeight: "400",
      fontFamily: "sans-serif",
    },
    作者名字: {
      fontSize: "1.5rem",
      color: "#AD8FC1",
      fontWeight: "800",
      fontFamily: "Zen Maru Gothic, sans-serif",
    },
    頁尾標題: {
      fontSize: "1rem",
      color: "#7CE6A8",
      fontWeight: "800",
    },
    頁尾內文: {
      fontSize: "1rem",
      color: "white",
      fontWeight: "200",
    },
    頁首連結: {
      fontSize: "1rem",
      fontWeight: "700",
      color: "black",
    },
    頁尾品牌名字: {
      fontSize: "1rem",
      color: "white",
      fontWeight: "400",
    },
    測試: {
      fontSize: "1rem",
      color: "#85E6AD",
      fontWeight: "800",
    },
    遮罩大字: {
      fontFamily: "Zen Maru Gothic, sans-serif",
      color: "white",
      fontSize: "4rem",
      fontWeight: "800",
    },
    遮罩小字: {
      color: "white",
      fontSize: "1.2rem",
      fontWeight: "800",
      fontFamily: "sans-serif",
    },
    h5: {
      fontSize: "1.4rem",
    },
    作品標題: {
      fontFamily: "Zen Maru Gothic, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "800",
      color: palette.primary,
    },
    預覽標題: {
      fontFamily: "Zen Maru Gothic, sans-serif",
      fontSize: "1.4rem",
    },
    關於內文: {
      fontSize: "1rem",
      color: palette.primary,
      fontWeight: "900",
    },
    致謝內文: {
      fontSize: "1.2rem",
      color: palette.primary,
      fontWeight: "800",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={10} autoHideDuration={3000} hideIconVariant />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);
