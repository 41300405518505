// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  increment,
  updateDoc,
} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAKwZwrzdFVCo3AMuNqT5oX6VKFt1VFf7Q",
  authDomain: "sensory-journey.firebaseapp.com",
  projectId: "sensory-journey",
  storageBucket: "sensory-journey.appspot.com",
  messagingSenderId: "617469747116",
  appId: "1:617469747116:web:d3507edb8ac1ae565d6409",
  measurementId: "G-SL9QZ5ZP5S",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const sendFeedback = async (from, to, content) => {
  const docRef = await addDoc(collection(db, "feedback"), {
    from,
    to: to || "匿名",
    content,
  });
  return docRef.id;
};

const getLikeCount = async () => {
  const interactionsRef = doc(db, "variables", "interactions");
  const docSnap = await getDoc(interactionsRef);
  if (docSnap.exists()) {
    const interactions = docSnap.data();
    return interactions["like-count"];
  } else {
    console.log("firebase error");
  }
};

const like = async () => {
  const interactionsRef = doc(db, "variables", "interactions");
  await updateDoc(interactionsRef, {
    "like-count": increment(1),
  });
};

export { getLikeCount, like, sendFeedback };
