import FeedbackPreview from "../components/FeedbackPreview";

const FeedbackStyle = {
  marginTop: "3rem",
  width: "100%",
  maxWidth: "85vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  alignContent: "center",
};

const Feedback = () => {
  return (
    <div className="feedback" style={FeedbackStyle}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "50rem",
          maxWidth: "100%",
          alignItems: "flex-start",
          gap: "1rem",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <FeedbackPreview />
        {/* <InDevelopmentMask /> */}
      </div>
    </div>
  );
};

export default Feedback;
