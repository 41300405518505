import { ImageList, ImageListItem } from "@mui/material";
import { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { cols } from "../db";

const ImageListItemStyle = {
  cursor: "zoom-in",
};

const ImageListPreview = ({ name, count }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = new Array(count)
    .fill(0)
    .map((_, index) => `/images/works/original/${name}/${index}.jpeg`);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <ImageList
      sx={{ width: "100%" }}
      variant="quilted"
      cols={cols[name]}
      gap={8}
    >
      {new Array(count).fill(0).map((_, index) => (
        <ImageListItem
          style={ImageListItemStyle}
          key={`${name}-work-${index}`}
          onClick={() => openImageViewer(index)}
        >
          <img
            src={`/images/works/compressed/${name}/${index} 大.jpeg?w=161&fit=crop&auto=format`}
            srcSet={`/images/works/compressed/${name}/${index} 大.jpeg?w=161&fit=crop&auto=format&dpr=2 2x`}
            alt={`${name}-work-${index}`}
            loading="lazy"
          />
        </ImageListItem>
      ))}
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </ImageList>
  );
};

export default ImageListPreview;
