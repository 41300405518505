import InstagramIcon from "@mui/icons-material/Instagram";
import LinkIcon from "@mui/icons-material/Link";
import { Avatar, Typography } from "@mui/material";
import { bios, instagrams, others } from "../db";

const ProfileHeaderStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "1rem",
  width: "100%",
};

const ProfileBodyStyle = {
  display: "flex",
  flexDirection: "row",
  justfiyContent: "center",
  alignItems: "center",
  gap: "1.2rem",
  width: "100%",
};

const ProfileStyle = {
  marginTop: "1rem",
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  borderRadius: "8px",
  border: "var(--font-primary) 2px solid",
  maxWidth: "85vw",
  width: "50rem",
  gap: "1rem",
};

const ProfileNameStyle = {
  display: "block",
  width: "6rem",
  wordBreak: "keep-all",
  borderBottom: "2px var(--font-primary) solid",
};

const Profile = ({ name }) => {
  return (
    <div style={ProfileStyle}>
      <div style={ProfileHeaderStyle}>
        <Avatar
          sx={{ width: 50, height: 50 }}
          alt="Profile Picture"
          src={`/images/avatar/${name} 小.jpeg`}
        />
        <div style={ProfileHeaderStyle}>
          <Typography style={ProfileNameStyle} variant="作者名字">
            {name}
          </Typography>
          <div
            style={{
              display: "flex",
              gap: ".5rem",
            }}
          >
            {instagrams[name] ? (
              <a href={instagrams[name]} target="_blank" rel="noreferrer">
                <InstagramIcon
                  className="link-icon"
                  color="primary"
                  sx={{
                    width: "2rem",
                    height: "2rem",
                  }}
                />
              </a>
            ) : (
              <></>
            )}
            {others[name] ? (
              <a href={others[name]} target="_blank" rel="noreferrer">
                <LinkIcon
                  className="link-icon"
                  color="primary"
                  sx={{
                    width: "2rem",
                    height: "2rem",
                  }}
                />
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div style={ProfileBodyStyle}>
        <Avatar
          style={{
            width: "1.8rem",
            height: "1.8rem",
            marginLeft: ".65rem",
            backgroundSize: "cover",
          }}
          alt="Profile Picture"
          src="/images/star.png"
        />
        <Typography variant="作品理念內文">{bios[name]}</Typography>
      </div>
    </div>
  );
};

export default Profile;
