import LockOpenIcon from "@mui/icons-material/LockOpen";
import { IconButton, TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { CenterFlex } from "../style";

const EasterEggWrapperStyle = {
  width: "fit-content",
};

const FormWrapperStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: ".5rem",
};

const EasterEgg = ({ prompt, content, answer }) => {
  console.log("content", content);

  const [attempt, setAttempt] = useState("");
  const [locked, setLocked] = useState(true);

  const handleAnswerChange = (event) => {
    setAttempt(event.target.value);
  };

  const handleUnlockClick = (event) => {
    if (answer.indexOf(attempt) !== -1) {
      setLocked(false);
      setAttempt("");
      enqueueSnackbar("🔓 成功解鎖！", { variant: "success" });
    } else {
      setAttempt("");
      enqueueSnackbar("🔒 解鎖失敗，再想想～", { variant: "error" });
    }
  };

  return (
    <div style={EasterEggWrapperStyle}>
      {locked ? (
        <>
          <div
            style={{
              ...CenterFlex,
              ...{
                marginBottom: "0.5rem",
              },
            }}
          >
            <Typography
              variant="作品理念內文"
              sx={{
                textAlign: "center",
                marginBottom: "1rem",
              }}
            >
              {prompt}
            </Typography>
          </div>

          <div style={FormWrapperStyle}>
            <TextField
              label="通關密語"
              placeholder="你，用心感受了嗎？"
              rows={1}
              variant="filled"
              color="primary"
              focused
              value={attempt}
              onChange={handleAnswerChange}
            />
            <IconButton
              color="primary"
              style={{
                marginTop: "0.5rem",
                fontWeight: "800",
              }}
              disableElevation
              onClick={handleUnlockClick}
            >
              <LockOpenIcon />
            </IconButton>
          </div>
        </>
      ) : (
        <>{content}</>
      )}
    </div>
  );
};

export default EasterEgg;
