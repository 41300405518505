import "@fontsource/noto-serif-tc";
import EmailIcon from "@mui/icons-material/Email";
import { Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import EasterEgg from "../components/EasterEgg";
import ImageListPreview from "../components/ImageListPreview";
import Profile from "../components/Profile";
import WorkSubtitle from "../components/WorkSubtitle";
import {
  concepts,
  counts,
  descriptions,
  easterEggAnswers,
  easterEggContents,
  easterEggPrompts,
  titles,
} from "../db";
import { CenterFlex, ColumnFlex, FullWidth } from "../style";

const FeedbackButtonStyle = {
  borderRadius: "3rem",
  width: "fit-content",
  marginTop: "1.5rem",
  paddingLeft: ".7rem",
  paddingRight: "1rem",
  border: "2px solid",
  fontSize: "1rem",
};

const EasterEggWrapperStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
};

const Work = () => {
  const navigate = useNavigate();
  const { name } = useParams();

  const handleFeedbackClick = () => {
    navigate(`/feedback?to=${name}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="paper-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "50rem",
          minHeight: "92vh",
          marginTop: "2rem",
          marginBottom: "3rem",
          gap: "1.2rem",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="作品標題">{titles[name]}</Typography>
          <Typography variant="body2" color="text.secondary">
            {name}
          </Typography>
        </div>
        <WorkSubtitle content="語音導覽" />
        <audio
          style={{
            width: "100%",
          }}
          controls
          src={`/audio/artists-words/compressed/${name}.mov`}
        ></audio>
        <ImageListPreview name={name} count={counts[name]} />
        <WorkSubtitle content="關於作品" />
        <Typography
          variant="作品理念內文"
          sx={{
            textAlign: "justify",
            paddingLeft: "1rem",
            paddingRight: "0.3rem",
          }}
        >
          {concepts[name]}
        </Typography>
        {descriptions[name] ? (
          <>
            <WorkSubtitle content="創作理念" />
            <Typography
              variant="作品理念內文"
              sx={{
                marginLeft: "1rem",
                paddingRight: "0.3rem",
              }}
            >
              {descriptions[name].split("\n").map((para, index) => (
                <p key={`description-${name}-${index}`}> {para} </p>
              ))}
            </Typography>
          </>
        ) : (
          <></>
        )}
        {easterEggPrompts[name] && (
          <>
            <WorkSubtitle content="用心感受" />
            <div style={EasterEggWrapperStyle}>
              <EasterEgg
                prompt={easterEggPrompts[name]}
                answer={easterEggAnswers[name]}
                content={easterEggContents[name]}
              />
            </div>
          </>
        )}
        <Profile name={name} />
        <div style={{ ...CenterFlex, ...ColumnFlex, ...FullWidth }}>
          <Button
            className="feedback-button"
            variant="outlined"
            startIcon={
              <EmailIcon
                style={{
                  borderRadius: "50%",
                  padding: "8px",
                  fontSize: "1.5rem",
                }}
              />
            }
            onClick={handleFeedbackClick}
            style={FeedbackButtonStyle}
          >
            我有話想說
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Work;
