import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import SendIcon from "@mui/icons-material/Send";
import { Button, ImageListItem, TextField, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import WorkSubtitle from "../components/WorkSubtitle";
import { concepts, names, titles } from "../db";
import { sendFeedback } from "../firebase";

const tos = ["策展團隊", ...names];
const descriptions = {
  策展團隊: "《感官日記》",
  ...titles,
};

const options = tos.map((to, index) => {
  return `${index >= 10 ? index : "0" + index}｜${to}／${descriptions[to]}`;
});

export default function FeedbackPreview() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(
    searchParams.get("to") ? tos.indexOf(searchParams.get("to")) : 0,
  );
  const [from, setFrom] = useState("");
  const [content, setContent] = useState("");
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSearchParams((prev) => ({ ...prev, to: tos[index] }));
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmitClick = () => {
    (async () => {
      const id = await sendFeedback(from, tos[selectedIndex], content);
      if (id) {
        setContent("");
        enqueueSnackbar("📨 成功送出，謝謝你／妳的回饋", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("🆘 傳輸失敗，請聯絡管理員", { variant: "error" });
      }
    })();
  };

  const handleFromChange = (event) => {
    setFrom(event.target.value);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  return (
    <>
      <WorkSubtitle content="回饋對象" />
      <List
        component="nav"
        aria-label="Device settings"
        style={{
          border: "2px solid rgb(105, 177, 135)",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          padding: "0",
          backgroundColor: "rgba(105, 177, 135, 0.05)",
        }}
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="回饋對象"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          startIcon={<ArrowDropDownCircleIcon />}
        >
          <Typography
            color="text.secondary"
            style={{
              fontWeight: "800",
            }}
          >
            {"🔽 "}
            {options[selectedIndex]}
          </Typography>
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
          style: { padding: 0 },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            disabled={index === selectedIndex}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            style={{
              backgroundColor: "#F8F8F5",
              width: "50rem",
              maxWidth: "85vw",
              fontWeight: "800",
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      {selectedIndex !== undefined && selectedIndex !== 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ImageListItem
            style={{
              width: "30rem",
              maxWidth: "100%",
            }}
          >
            <img
              src={`/images/cover/compressed/${tos[selectedIndex]} 大.jpeg`}
              srcSet={`/images/cover/compressed/${tos[selectedIndex]} 大.jpeg`}
              alt={`${tos[selectedIndex]}-cover}`}
              loading="lazy"
            />
          </ImageListItem>
        </div>
      )}
      {selectedIndex !== undefined && selectedIndex !== 0 && (
        <WorkSubtitle content="關於作品" />
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {concepts[tos[selectedIndex]]}
      </div>
      <WorkSubtitle content="回饋感受" />
      <TextField
        label="我是"
        placeholder="（匿名）"
        variant="filled"
        color="primary"
        focused
        value={from}
        onChange={handleFromChange}
      />
      <TextField
        label="我想說"
        required
        placeholder={
          selectedIndex === 0
            ? "我很喜歡這個展覽，因為⋯"
            : "我很喜歡你／妳的作品，因為⋯"
        }
        fullWidth
        multiline
        rows={4}
        variant="filled"
        color="primary"
        focused
        value={content}
        onChange={handleContentChange}
      />
      <Button
        color="primary"
        startIcon={<SendIcon />}
        style={{
          marginTop: "0.5rem",
          fontWeight: "800",
        }}
        disableElevation
        fullWidth
        onClick={handleSubmitClick}
        disabled={content === "" || selectedIndex === undefined}
      >
        送出回饋
      </Button>
    </>
  );
}
