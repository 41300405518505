const FullWidth = {
  width: "100%",
};

const CenterFlex = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const ColumnFlex = {
  display: "flex",
  flexDirection: "column",
};

export { CenterFlex, ColumnFlex, FullWidth };
