import { Avatar, Badge, Box, Tooltip } from "@mui/material";
import PreviewCard from "../components/PreviewCard";
import { names, titles } from "../db";

const GalleryStyle = {
  marginBottom: "3rem",
  minHeight: "92vh",
};

const SenseTypeStyle = {
  display: "flex",
  alignItems: "space-bewteen",
  justifyContent: "center",
  alignSelf: "center",
  gap: "2rem",
  marginBottom: "1rem",
  flexWrap: "wrap",
};

const GalleryConatinerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1.5rem",
  paddingTop: "2.5rem",
};

const senseIcons = ["聆", "觀", "觸", "嗅", "品"];

const Gallery = () => {
  return (
    <div style={GalleryConatinerStyle}>
      <div style={SenseTypeStyle}>
        {senseIcons.map((icon) => {
          return (
            <Tooltip
              arrow
              title={icon}
              placement="top"
              key={`sense-icon-${icon}`}
            >
              <Badge
                className="sense-icon"
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Avatar
                  className="sense-icon-avatar"
                  alt={`${icon} Icon`}
                  src={`/images/sense-icon/${icon}.png`}
                />
              </Badge>
            </Tooltip>
          );
        })}
      </div>
      <Box
        style={GalleryStyle}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "start",
          alignContent: "flex-start",
          flexDirection: "row",
          gap: "2.5rem",
        }}
      >
        {names.map((name, index) => {
          console.log(`rotate(${(index % 2 ? -1 : 1) * 3}deg)`);
          return (
            <PreviewCard
              index={index + 1}
              key={`preview-card-${index}`}
              name={name}
              title={titles[name]}
            />
          );
        })}
      </Box>
    </div>
  );
};

export default Gallery;
