import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";

const easterEggPrompts = {
  葉書碩: "世界自殺預防日",
  石瑛: "輕觸作品後，你覺得海面下藏著什麼？",
};

const easterEggAnswers = {
  葉書碩: ["0910", "09.10", "9.10", "9/10", "09/10", "9月10日", "9 月 10 日"],
  石瑛: [
    "水",
    "水袋",
    "軟軟的東西",
    "軟的東西",
    "熱熔膠",
    "膠帶",
    "塑膠袋",
    "雙面膠",
    "夾鏈袋",
    "袋子",
  ],
};

const shouEasterEggCaptions = `2018.01.28
少數你為我拍下的照片

2018.03.17
買了卡片和禮物
想當成週年紀念
但那天從來沒有到來

2018.03.22

2018.05.12
常一起去的咖啡廳
第一次得到微笑
也是最後一次

2018.07.16
妳說腳受傷
不能陪我聽音樂會
卻跟朋友橫跨了整個安森

2018.07.27
那些做不到的承諾

2018.08.13
遲遲等不到
妳的回覆

2018.08.24
將自己
和對妳的思念
埋在無數的
實驗中

2018.08.27
Sun in the room

2018.08.29
Sunset in the room

2018.09.09
抽血、洗胃、縫合
傷害自己的人
得待在精神科`.split("\n\n");

const easterEggContents = {
  葉書碩: (
    <ImageList cols={2} gap={4} sx={{ width: "50rem", maxWidth: "90vw" }}>
      {shouEasterEggCaptions.map((caption, index) => (
        <ImageListItem key={`easter-egg-item-${index}`}>
          <img
            src={`/images/works/compressed/葉書碩/彩蛋/easter-${caption.substring(
              0,
              10,
            )}.jpg?w=48&fit=crop&auto=format`}
            srcSet={`/images/works/compressed/葉書碩/彩蛋/easter-${caption.substring(
              0,
              10,
            )}.jpg?w=48&fit=crop&auto=format&dpr=2 2x`}
            alt={`$葉書碩-easter-egg-${index}`}
            loading="lazy"
          />
          <ImageListItemBar
            subtitle={
              <div style={{ display: "flex", flexDirection: "column" }}>
                {caption
                  // .substring(10)
                  .split("\n")
                  .map((p, index) => (
                    <Typography
                      style={{
                        width: "100%",
                        wordBreak: "break-all",
                        fontSize: ".5rem",
                        textAlign: "center",
                      }}
                      key={`p-${index}`}
                    >
                      {p}
                    </Typography>
                  ))}
              </div>
            }
            position="below"
          />
        </ImageListItem>
      ))}
    </ImageList>
  ),
  石瑛: <Typography>--錄音檔待補--</Typography>,
};

const musicDescription = `音樂創作：陳筠珊

我是在沒睡覺的那個凌晨把所有參展者的錄音檔載下來聽，有人在耳邊說話彷彿夜晚就不那麼寂寞了（儘管說的是一些與我無關的創作理念）。綜觀大家的作品其實很難找出一個明確的定調，但我覺得某種程度而言都體現了二十到三十這個年歲裡的真誠，錄音檔裡的喃喃自語，都嚴謹而用力地述說著他們的某個人生故事。

夜被熬過去的那日清晨我直奔台大琴房，三個鐘頭之內即興了一些音樂。有的在過程中毀壞、崩塌，只有這首被留了下來。我無法為音樂命名，命名終究是自私的事，但這段旋律應該像公有物一樣地被擺在展場裡頭，讓路過的人們用感官去感受，自行賦予定義。`;

const palette = {
  primary: "#69b187",
};

const statement = `攝影行為紀錄下的不只是影像本身，

對攝影者來說，
當下的感受、情緒、思考也一併被記載，

彷彿在未來的任何時機，
只要看到影像就能瞬間回溯當下，
咖啡豆瀰漫在空氣中的香、

林間潮濕冷冽的空氣、

聊天室裡錯置的對話⋯

\

創作者藉由攝影眼觸摸、傾聽、感受這個世界，

並以影像為主其他媒材為輔記載個體感官經驗，

透過觀者在展場中任由五感前往感受，

進入創作者的影像日記中加載各種感官記憶。`;

const credits = `/策展團隊/

曾妤勤 林其蓁 陳筠珊 顧寬証

/參展人/

林祐群 葉書碩 林玟伶 U0 陳奕愷 李建霖
許哲睿 徐振瑜 林繼堽 簡廷翰 石瑛

/特別感謝/

彭菘瑋 楊鎮豪
張庭瑋 詹敏華


/視覺設計/

曾妤勤

/網頁製作/

顧寬証

/音樂創作/

陳筠珊`;

const names = [
  "簡廷翰",
  "林祐群",
  "石瑛",
  "林玟伶",
  "徐振瑜",
  "葉書碩",
  "林繼堽",
  "李建霖",
  "U0",
  "許哲睿",
];

const counts = {
  葉書碩: 3,
  林玟伶: 7,
  李建霖: 1,
  林祐群: 3,
  林繼堽: 1,
  石瑛: 2,
  U0: 4,
  許哲睿: 12,
  徐振瑜: 5,
  簡廷翰: 4,
};

const titles = {
  葉書碩: "semicolon",
  林玟伶: "一面牆的厚度",
  李建霖: "發呆・蘭嶼",
  林祐群: "咖啡日記",
  林繼堽: "Cat & Camera",
  石瑛: "Death In the Sea",
  U0: "非同步溝通",
  許哲睿: "異曲同工 x 相由自造",
  徐振瑜: "forest",
  簡廷翰: "memories",
};

const cols = {
  葉書碩: 1,
  李建霖: 1,
  林玟伶: 2,
  石瑛: 1,
  徐振瑜: 2,
  許哲睿: 3,
  林繼堽: 1,
};

const bios = {
  葉書碩: `我是葉書碩，24歲。
      嘗試將情感注入不同的媒材，最近使用的是照片。將情感濃縮成影像，稀釋成思考與共鳴。`,
  李建霖: `七月初去蘭嶼旅行，帶著一身屬於蘭嶼的膚色回到台北。`,
  林祐群: `A postdoc in NTHU. Research interest: gravitational waves, deep learning, multi-messenger astronomy. Mainly use large format camera for photography works.`,
  U0: `被幸運過度飼養而貪婪，時常因此四分五裂。`,
  簡廷翰: `哈囉，我是簡廷翰，大二，男生，平時喜歡拍照玩玩遊戲，目前正在苦惱何謂藝術、該怎麼去嘗試。`,
  許哲睿: `拾起相機剛滿一年，喜歡心理學、神秘學、冰淇淋以及閱讀小說。熱愛雙拼創作，透過兩張照片的並置創造新的觀看視角，為尋常的生活場景增添趣味。近期的煩惱是如何拍出具有靜物感的人像。`,
  林玟伶: `林玟伶，喜歡拍照、透過影像之特殊性與在意的事物產生各種創作。`,
  石瑛: `生活常在隨心所欲和保持規律中來回拉扯，目前作息時區大概在斐濟，但是生活熱情被泡在台北潮濕曖昧的天氣裡。喜歡海，喜歡音樂，喜歡辣和甜食，也喜歡冬天曬過太陽的棉被。覺得自己蛀牙的時候會忍一忍，希望痛覺自己消失。`,
  徐振瑜: `自然保留區位在象山山腳吳興街公寓裡的一台 Sony 相機中。`,
  林繼堽: `業餘攝影師，鐵道迷。`,
};

const others = {
  葉書碩: "https://linktr.ee/shuo_film",
  林祐群: "https://zsefbhuk747.myportfolio.com/",
};

const instagrams = {
  葉書碩: "https://www.instagram.com/shuo_film/",
  林玟伶: "https://www.instagram.com/reinvent_ir/",
  李建霖: 1,
  林祐群: "",
  林繼堽: "https://instagram.com/cklin_photo/",
  石瑛: "https://instagram.com/sying_pho_o/",
  U0: "https://www.instagram.com/tsai_u0/",
  許哲睿: "https://instagram.com/5222hallucination/",
  徐振瑜: "https://www.instagram.com/jhenyu_jpg/",
  簡廷翰: "https://www.instagram.com/han_film/",
};

const concepts = {
  葉書碩: `我們想殺死的，只是那份悲傷。`,
  林祐群: `我的一天是從一杯咖啡開始的。透過調製咖啡的儀式，咖啡的香氣，冷熱的觸感，以及口感讓我準備好面對一天的挑戰。`,
  林玟伶: `一面在影像中的牆只是一層表面，又或者能因影像之特性而擴增其厚度？`,
  U0: `遙遠的距離迫使對話遷徙至網路聊天室中，而非同步性於此恣意蔓延。這樣的斷裂和失真的盡頭會是什麼呢？`,
  李建霖: `在蘭嶼的海岸線發呆`,
  許哲睿: `排版靈感取自郭德堡變奏曲，透過相似符號論調個人生命經驗，並在每組照片中安插一個意象或是故事。`,
  徐振瑜: `森林中，五感與靈性交融。
      嘗試把意識遁入周遭，與身旁一草一木共用各式感官。
      `,
  林繼堽: `分享之前拍過的貓咪照片，帶給大家快樂`,
  石瑛: `本件作品以理想中殯葬方式為創作起點，試圖描繪一個在海與陽光中平靜的死亡意象。`,
  簡廷翰: `一些我喜歡的瞬間`,
};
const descriptions = {
  葉書碩: `大家好，我是《semicolon》的作者葉書碩。這個作品的緣起要從一間餐酒館說起。

  某個週日的傍晚，與朋友爬完山後回到了住家附近的餐酒館聚餐。人來人往的店內，我看見一位店員的雙手手臂都是美工刀的割痕，整齊地像條碼一字排開，傷口的邊緣還有些許的泛紅，這應該是不久前發生的事。
  
  這讓我想起一位很好的朋友曾經向我透露，在高中時也會這樣傷害自己，而她會這樣跟我說，正是因為知道我也這樣傷害自己。
  
  我們很難一直都知道自己在想什麼，所有動作背後的真實動機都不只有一個，特別是在承受壓力的狀況下，我們更容易脫離常軌。在曾經的一段感情中，我失去了平衡，將一切的需要與希望都寄託在他人身上，而一切被強制抽離的痛苦讓我不得不尋找出口，做了那樣的選擇。
  
  在作品中用了三種不同材質的紙說著不同的故事。貼在盒子上的幾張紙，大家會先看到自縊的畫面，打開盒子後會發現其實有人正在阻止一切的發生。中間有許多關於那段失去平衡期間拍下的照片與故事。最後是一本小冊，火柴點亮了又熄滅，點亮了又熄滅，就像生命的開始與結束。
  
  其中一個盒子中裝著分號的紋身貼紙。在2018年由Project semicolon在Twitter 上發起的活動，宗旨為幫助精神疾病、自殘、成癮與自殺的人們，將身上的刺青與分號結合，代表生命並沒有真的結束，只是一個休止符。
  
  我還是一直惦記著那位店員，當時很想關心她，但我知道關心與被關心都是需要勇氣的，所以我希望透過這樣的作品作為邀請卡，去讓她知道她並不是孤單的。
  
  這份作品將作為長期攝影計畫的開端，去幫助其他人找到合適的出口。歡迎有興趣加入的人與我一起創作。`,
  林玟伶: `10秒、20秒、30秒...；0cm、0.5m、1cm...作品透過暗房實驗，將同一張照片重複放相，因時間差異造成的明暗不均、移動的物理空間所造成的影像間隙與模糊，使留下時空痕跡之影像得以被意識到。
  
      重新將照片放回那面牆之後，它們各自所經歷的，與牆面因於物理時空下所產生的痕跡形成差距，影像出自於牆面而後獨立於牆面，兩者在當下出現新的關係。`,
  U0: `這本書算是一個創作計畫的呈現，主要是許多朋友們因為讀書、工作等原因四散世界各地，巨大的時差使我們在聊天的過程時常會有很大的延遲。而網路聊天室的特性有時候也讓我們對話的順序也變得破碎和混亂(比如有時候我依順序問了ABC三個問題，但對方回覆可能會是CBA的順序)，此外無法用手勢、表情等視覺資訊輔助的情況下，文字的失真也比面對面溝通更加明顯。
      在過去幾次因為文字的失真而遇到各種好笑或好氣的事情後，再次面對它時反而開始好奇這種斷裂的極致會是甚麼樣子，因此和奕愷約好以一個月的時間，彼此只用照片在一個獨立的網路聊天室對話，最後將這些照片分別裝訂成一本書並陳給觀眾們，讓觀眾們可以自行猜測、安排我們的對話，進一步擴大失真。
      在裝幀上，我刻意讓兩本子書以經摺裝的方式去保留網路聊天室中對話"串"的特性，觀眾也可以完全拉開這七十幾頁的影像，去感受那一整個月的照片語言。`,
  李建霖: `我七月中的時候一個人到蘭嶼旅行，旅途中經常在海岸線、堤防上、沙灘上發呆，這張照片就是我在蘭嶼的某個海邊角落拍下的，希望大家在看這張照片的時候可以看到蘭嶼的海，跟我一起發呆`,
  許哲睿: `排版靈感取自郭德堡變奏曲，透過相似符號論調個人生命經驗，並在每組照片中安插一個意象或是故事。背景的歌曲就是郭德堡變奏曲，有興趣的人請上我們的網站，我有把標題放在那。 YouTube搜尋：Bach - Aria mit 30 Veränderungen 'Goldberg Variations' BWV 988 - Rondeau | Netherlands Bach Society
  
      回來談本次作品，大交集下作品的意義似乎就變得非常個人化、破碎化，所以大家可以試著用自己的想法去解讀，每組照片、每頁之間究竟在述說什麼故事，正確與否不重要，對正在看的你而言有趣、有意義便足以～自攝影開始就深受川內倫子影響，但我對於具體想指稱的事物還不夠清晰，對於人生體悟想得還不夠透徹。
      
      我的拍照風格屬於街拍類，沒有特別關注在什麼領域，拍下照片、回家整理，拍的照片像是素材，而創作主要發生的場所則在LR、PS中。
      
      漫無目的的隨手拍，也許是年輕的優勢。我對藝術有興趣，但沒有特別的想法，所以透過大量拍攝與歸納，瞥見了那個時期自我腦袋的運作方式，風格在每次煉化中稍稍成形。
      
      近期開始上比較專門的攝影課，也想透過小說、宗教去創建我的系列作品。
      `,
  徐振瑜: `為了躲避平地酷暑，暑假跑了台灣好幾處的山中。
      台灣的森林遊樂區多是伐木時代遺留下來的基地，通常座落在中海拔霧林帶之中，高度大約落在2000公尺上下，依照每100公尺下降0.6攝氏度的方式計算，夏季溫度是舒適且怡人的。
      很喜歡走在神木林之中的感覺，空氣冷冽微濕、帶有一點點檜木香味，安靜帶有一點神秘氣息，眼見雲霧流動之間，巨木巍峨莊嚴，而身旁小草散發出濃濃生機。
      我嘗試把自己的感官放逐，讓它們自由散佈四周，在巨木樹皮中的隙縫內、在張牙舞爪的枝椏中、在自由流動的霧靄裡，似與天地合而為一。`,
  石瑛: `這次作品的創作初衷是我在思考想要以什麼樣的方式殯葬，以什麼樣的姿態和世界告別。可能因為那陣子很喜歡海，所以在腦海裡描繪了一個仰躺在海面，被水輕輕簇擁，傍晚時分陽光撒下來的畫面，這也就成為了這次作品的起點。後來到安寧病房做服務學習，看到了雖然生命末期生理上的支離破碎，但似乎心理上我們是可以嘗試追求一個平靜安詳，甚至某種程度上，溫暖的死亡，所以這也成為這件作品想要傳達給大家的感受。而向日葵，作為死亡的主體，會在展期中慢慢凋零，呈現正在進行式的、單向的、不可挽回的動態變化。也希望大家除了用眼睛觀看這件作品，還能用手輕輕觸摸，感受海面底下的湧動。`,
  簡廷翰: `就是一些過去拍的照片，我認為那些能讓你回憶起拍這張照片的動機的影像都是一種日記吧，畢竟也是一種記錄性的行為，不同於隨筆揮毫，而是對我而言有記憶點的照片，可能是第一次拿這台相機上街拍照，可能是尚屬於摸索階段的試拍，但是都是很重要的回憶。`,
};

export {
  bios,
  cols,
  concepts,
  counts,
  credits,
  descriptions,
  easterEggAnswers,
  easterEggContents,
  easterEggPrompts,
  instagrams,
  musicDescription,
  names,
  others,
  palette,
  statement,
  titles,
};
